.whatsapp {
border: 0;
position: fixed;
top: 88%;
right: 2%;
z-index: 5;
}
.whatsapp img{
width: 70px;
height: 70px;

}

@media (max-width:991px) {
    .whatsapp img{
        width: 50px;
        height: 50px;
    }
    
}