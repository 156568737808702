
.portfolio-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
    padding-inline: 50px;
  }
.cta-wrapper{
    margin-block-start: 100px;
    width: 70%;
    height: 400px;
    border-radius: 10px;
    background-image: linear-gradient(146deg, #110467, #07031c 25%, #051434 55%, #030923 75%, #030d0f 101%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cta-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: white;
    text-align: center;
}
@media screen and (max-width:786px) {   
    .portfolio-content{
        flex-direction: column;
        padding-inline: 20px;

      }
      .cta-wrapper{
        width: 95%; 
    }
  }