/* ---------------- websolution page starts ------------------ */
.web-soln-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 80px;
}

.web-soln-top-txt-container p{
    margin-bottom: 0.5rem!important;
}

.web-soln-top-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.web-soln-top-img-container img{
    width: 600px;
}
.responsive-design{
    display: flex;

}
.platform-design{
    display: flex;
}

.web-soln-bottom{
    text-align: center;
}

.card-parent{
    display: flex;
    justify-content: space-between;
    padding: 30px 250px;
}

.bfb-card{
    width: 400px;
    background-color: #f8f8f8;
    padding: 20px 20px 1px 20px;
}

.bfb-card img{
    width: 100%;
}

.bfb-card p{
    text-align: left;
}

.ico-orange{
    color: #f15c34;
}
.image-none1{
    display: none;
}

/* media queries */
@media (max-width: 1320px){
    .card-parent{
        padding: 30px 150px;
    }
}

@media (max-width: 1170px){
    .card-parent{
        padding: 30px 100px;
    }
}

@media (max-width: 1130px){
    .web-soln-top-img-container img{
        width: 400px;
    }

    .web-soln-top-txt-container{
        padding-top: 0px;
    }

    .card-parent{
        padding: 30px 50px;
    }
}

@media (max-width: 912px){
    .card-parent{
        flex-direction: column;
        align-items: center;
    }

    .bfb-card{
        margin-top: 20px;
    }
}

@media (max-width: 991px){
    .web-soln-top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    padding: 10px 20px;

    }
    .responsive-design{
        display: flex;
        flex-direction: column;
    }
    .platform-design{
        display: flex;
        flex-direction: column;

    }
    .image-none{
        display:none
    }
    .image-none1{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .web-soln-top-txt-container{
        padding-top: 60px;
        padding-left: 0px;
    }
}

@media (max-width: 560px){
    .web-soln-top-img-container img{
        width: 300px;
    }
}

@media (max-width: 460px){
    .web-soln-top{
        padding-left: 20px;
    }

    .bfb-card{
        width: 350px;
    }
}

@media (max-width: 400px){
    .bfb-card{
        width: 280px;
    }
}

/* ---------------- websolution page ends ------------------ */