.contact-contents{
    margin-top: 30px;
}
.full-icon{
  margin-block-start: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-block-end: 50px;
  gap: 30px;
  
}
.icon{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color:#f15d342d;
  color: #f15d34;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-body h6{
  margin-block-start:15px ;
}
.form-map{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  border-radius: 10px;
}
.form-map iframe{
  width: 600px;
  height:500px; 
}
.form-map input{
  margin-bottom: 30px;
}
.contact-form {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .form-control {
    background-color: #fafafa;
    border: none;
  }

  .form-group {
    margin-bottom: 25px;
  }
  
  .form-group label {
    margin-bottom: 25px; 
  }
  
  .btn-dark {
    background-color: black;
    color: white;
    border: none;
  }

  .send-button {
    margin-top: 20px;
  }

  @media screen and (max-width:768px){
    .form-map{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0px;
    }
    .form-map iframe{
      width: 100%;
      height:350px; 
    }
  }