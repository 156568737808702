
.website-pro{
  width: 450px;
  height:auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 15px;

}
.website-pro img{
  width: 100%;
  height: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

}
.porjects-title{
  background-color:#0f2048;
  color: rgb(249,250,251);
  width: 100%;
  height: 100px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-inline-start: 1%;
  display: flex;
  flex-direction: column;
  justify-content:center;
}
.porjects-title h4{
  font-size: 20px;
}
.all-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 20px;
}
.title-btn a{
  color: inherit;
  text-decoration: none;
}
.title-btn button{
  border-radius: 100px;
  width: 125px;
  background-color: #182f66;
  border-color: #172e63;
}
.arrow-btn{
  background-color: white;
  color: rgb(2,49,58);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: inline-end;
  padding-top: -50px;
}


@media screen and (max-width:1250px) {
  .website-pro{
    width: 450px;
  }
}
@media screen and (max-width:786px) {
  .website-pro{
    width: 95%;
   background-size: cover;
  }
  .arrow-btn{
   display: none;
  }
}





  


