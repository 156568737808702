.product-category-card {
    width: 250px;
    height: 200px;
  }
  
  .product-category-inner {
    position: relative; 
  }
  
  .image-container {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s; 
  }
  
  .image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(62, 62, 62, 0.5);
    z-index: 1;
  }
  
  .product-category-inner img {
    width: 100%;
    height: 170px;
    z-index: 0;
    transition: transform 0.3s; 
  }
  
  .product-category-inner:hover img {
    transform: scale(1.1); 
  }
  
  .orange-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f16a50;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    z-index: 2; 
    border-radius: 8px;
  }
  
  .product-category-txt{
    text-align: center;
  }

  .product-category-link{
    color: blue;
    cursor: pointer; 
  }

  /* media queries */
  @media (max-width: 550px){
    .product-category-inner{
      width: 250px;
      height: 200px;
    }

    .product-category-card {
      margin-top: 50px;
    }
  }

  