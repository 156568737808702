/* --------- digital marketing page starts --------- */
.digi-mrkt-bottom h3{
    text-align: center;
}

.digi-mrkt-bottom-card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    gap: 30px;
    padding: 0 110px;
}

/* media queries */

@media (max-width: 1320px){
    .digi-mrkt-bottom-card-container{
        padding: 0 80px;
    }
}

@media (max-width: 1220px){
    .digi-mrkt-bottom-card-container{
        padding: 0 50px;
    }
}

@media (max-width: 1160px){
    .digi-mrkt-bottom-card-container{
        padding: 0 20px;
    }
}

@media (max-width: 1160px){
    .digi-mrkt-bottom-card-container{
        padding: 0 100px;
    }
}

@media (max-width: 700px){
    .digi-mrkt-bottom-card-container{
        justify-content: center;
    }
}

/* --------- digital marketing page ends --------- */